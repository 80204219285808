import * as d3 from "d3-color";

const INC = 0.4;
const RANGE = 4.1;

// copied from https://gist.github.com/jfsiii/5641126
//// from http://www.w3.org/TR/WCAG20/#relativeluminancedef
function luminance({ r, g, b }) {
  var RsRGB = r / 255;
  var GsRGB = g / 255;
  var BsRGB = b / 255;

  var R =
    RsRGB <= 0.03928 ? RsRGB / 12.92 : Math.pow((RsRGB + 0.055) / 1.055, 2.4);
  var G =
    GsRGB <= 0.03928 ? GsRGB / 12.92 : Math.pow((GsRGB + 0.055) / 1.055, 2.4);
  var B =
    BsRGB <= 0.03928 ? BsRGB / 12.92 : Math.pow((BsRGB + 0.055) / 1.055, 2.4);

  // For the sRGB colorspace, the relative luminance of a color is defined as:
  var L = 0.2126 * R + 0.7152 * G + 0.0722 * B;
  return L;
}

function contrast(lumA: number, lumB: number) {
  if (lumA < lumB) {
    let tmp = lumA;
    lumA = lumB;
    lumB = tmp;
  }
  return (lumA + 0.05) / (lumB + 0.05);
}

function makeCssVariables(colors: string[]) {
  return colors.map((c, idx) => `--mono-tint-${idx}: ${c};`).join("\n");
}

function makeColors() {
  const parentEl = document.getElementById("renderzone") as HTMLDivElement;
  const colorEl = document.getElementById("midcolor") as HTMLInputElement;
  const midColor = d3.lch(colorEl.value);
  const minContrast = Number(
    (document.getElementById("mincontrast") as HTMLInputElement).value,
  );
  const heading = (document.getElementById("msg") as HTMLInputElement).value;

  if (Number.isNaN(midColor.h)) {
    colorEl.style.background = "salmon";
    function handlePress() {
      colorEl.style.background = "white";
    }
    colorEl.addEventListener("keydown", handlePress, { once: true });
    return;
  }

  parentEl.innerHTML = "";

  const tints = [];
  for (let i = -1 * RANGE; i <= RANGE; i += INC) {
    const bg = d3.lch(midColor).brighter(i).formatHex();
    if (bg == "#000000" || bg == "#ffffff") {
      continue;
    }
    const bgLum = luminance(d3.rgb(bg));
    const sign = bgLum > 0.5 ? 1 : -1;

    tints.push(bg);

    for (let j = sign * -1 * RANGE; sign * j <= RANGE; j += sign * INC) {
      const fg = d3.lch(midColor).brighter(j).formatHex();
      const swatchContrast = contrast(luminance(d3.rgb(fg)), bgLum);
      if (fg == "#000000" || fg == "#ffffff" || swatchContrast < minContrast) {
        continue;
      }
      const el = document.createElement("div");
      el.classList.add("renderblock", "rounded");
      el.style.backgroundColor = bg;
      el.style.color = fg;
      el.innerHTML = `<h1>${heading}</h1>
                        <h2>bg: ${bg}</h2>
                        <h3>fg: ${fg}</h3>
                        <p>contrast ratio: ${swatchContrast.toPrecision(4)}</p>`;
      const b = document.createElement("button");
      b.innerText = "copy css";
      b.style.color = bg;
      b.style.backgroundColor = fg;
      b.addEventListener("click", () => {
        navigator.clipboard.writeText(
          `background-color: ${bg};\ncolor: ${fg};`,
        );
      });
      el.appendChild(b);
      parentEl.appendChild(el);
    }
  }

  console.log(makeCssVariables(tints));
}

document.addEventListener("DOMContentLoaded", () => {
  makeColors();
  document.addEventListener("keypress", (e) => {
    if (e.keyCode === 13) {
      makeColors();
    }
  });
  const buttonEl = document.getElementById("thebutton") as HTMLButtonElement;
  buttonEl.addEventListener("click", () => {
    makeColors();
  });
});
